

(function ($, undefined) {
	// Is History API reliably supported? (based on Modernizr & PJAX)
	if (!(window.history && history.pushState && window.history.replaceState)) {
		return console.error('history.nette.ajax.js: History API is missing.');
	}

	$.nette.ext('redirect', false);

	var findSnippets = function () {
		var result = {};
		$('[id^="snippet-"]').each(function () {
			var $el = $(this);
			if (!$el.is('[data-history-nocache]')) {
				result[$el.attr('id')] = $el.html();
			}
		});
		return result;
	};

	$.nette.ext('history', {
		init: function () {
			this.history.replaceState(this.lastPushedState = this.createState(
				document.title,
				window.location.href,
				{},
				findSnippets(),
			), document.title, window.location.href);

			$(window).on('popstate.nette', $.proxy(function (ev) {
				this.onPopstate(ev);
			}, this));
		},
		before: function (xhr, settings) {
			if (!settings.nette) {
				this.url = null;
			} else if (!settings.nette.form) {
				this.url = settings.nette.ui.href;
			} else if (settings.nette.form.get(0).method === 'get') {
				this.url = settings.nette.form.get(0).action || window.location.href;
			} else {
				this.url = null;
			}
		},
		success: function (payload) {
			var redirect = payload.redirect || payload.url; // backwards compatibility for 'url'
			if (redirect) {
				var regexp = new RegExp('//' + window.location.host + '($|/)');
				if (!((redirect.substring(0, 4) === 'http') ? regexp.test(redirect) : true)) {
					window.location.href = redirect;
					return;
				}
			}

			var url = null;
			if (payload.redirect) {
				url = payload.redirect;
			} else{
				url = this.url;
			}

			console.log(this);
			if (url && url != window.location.href) {
				//console.info("pushed state");
				this.history.pushState(this.lastPushedState = this.createState(
					payload.snippets['snippet--title'],
					url,
					payload.state,
					payload.snippets
				), payload.snippets['snippet--title'], url);
			} else { // znovuobnovení stránky (chyba ve formuláři, signál, ...)
				//console.info("replaced state", this.lastPushedState);
				//console.info("         with:", payload);
				var state = this.lastPushedState.state;
				var uiSnippets = this.lastPushedState.ui.snippets;
				Object.assign(state, payload.state);
				Object.assign(uiSnippets, payload.snippets);

				this.history.replaceState(this.lastPushedState = this.createState(
					this.lastPushedState.title,
					this.lastPushedState.url,
					state,
					uiSnippets
				), this.lastPushedState.title, this.lastPushedState.url);
			}
		}
	}, {
		history: window.history,
		url: null, // used for catch url in request
		lastPushedState: null,
		createState: function(title, url, state, snippets) {
			var historyState = {
				title: title,
				url: url,
				state: state,
				ui: {
					snippets: snippets,
				}
			};

			//console.info("historyState created:", historyState);
			return historyState;
		},
		onPopstate: function(ev) {
			var state = ev.originalEvent.state;

			if (state.title) this.handleTitle(state.title);
			if (state.ui) this.handleUI(state.ui);
		},
		handleTitle: function (title) {
			document.title = title;
		},
		handleUI: function (ui) {
			var snippetsExt;
			if ( snippetsExt = $.nette.ext('snippets') ) {
				var snippets = {};
				$.each(ui.snippets, function (key, val) {
					snippets[key] = val;
				});
				snippetsExt.updateSnippets(snippets, true);
				$.nette.load();
			}
		},
	});
})(jQuery);